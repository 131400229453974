<template>
  <div class="bbs-main">
    <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
      <van-list class="bbs-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="initList">
        <BBSList v-for="(item, index) in listData" :key="index" :item="item" @onRefresh="onRefresh" />
      </van-list>
    </van-pull-refresh>
    <div class="posted" @click="posted">发帖</div>
  </div>
</template>

<script>
import Http from '@/utils/Http.js';

import { Image as VanImage, List, PullRefresh } from 'vant';

import { handlerEror } from '@/utils/Tools';

import BBSList from '@/components/BBS/List.vue';

import { OPEN_NEW_WEBVIEW_IP } from '@/utils/Agreement.js';

const CommunityList = 'cloud/ec/tally/getCommunityList';

export default {
  components: {
    BBSList,
    VanImage,
    vanList: List,
    vanPullRefresh: PullRefresh
  },

  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      pageData: {
        pageNum: 1,
        pageSize: 10
      },
      listData: []
    };
  },
  methods: {
    posted() {
      // 发帖和详情 需要开新容器
      window.location.href = `${OPEN_NEW_WEBVIEW_IP}/posted?isNavHidden=1`;
      // this.$router.push({
      //   path: '/posted'
      // });
    },
    initList(isInit) {
      this.loading = true;

      Http.get(CommunityList, this.pageData)
        .then((data) => {
          this.loading = false;
          this.refreshing = false;

          if (data.object.length) {
            if (isInit) {
              this.listData = [];
            }
            this.listData.push(...data.object);
            this.pageData.pageNum++;
          } else {
            this.finished = true;
          }
        })
        .catch((err) => {
          this.finished = true;
          this.loading = false;
          handlerEror(err);
        });
    },
    onRefresh() {
      this.finished = false;
      this.pageData.pageNum = 1;

      this.initList(true);
    }
  },
  created() {
    window['refresh'] = () => {
      this.onRefresh();
    };
  }
};
</script>

<style lang="less" scoped>
.bbs-main {
  .posted {
    position: fixed;
    right: 20px;
    bottom: 40px;
    width: 55px;
    height: 55px;
    opacity: 1;
    background: #51ccb4;
    border-radius: 50%;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 55px;
    box-shadow: 0px 0px 2px 2px #51ccb4;
  }
}
</style>
